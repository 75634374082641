import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useModuleAdmin } from "./useModuleAdmin";

interface ConfigAdminInput {
}

export function useConfigAdmin(props:ConfigAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedModule, callModuleAdmin } = useModuleAdmin(props, {emit})

  const config:Ref<any> = ref({
    defaultValues : {
      envelopeId: '',
      party: {
          type: '',
          role: '',
          name: ''
      },
      referencePeriod: {
          month: '',
          year: '',
      },
      psiId: '',
      declarationTypeCode: '1',
      flowCode: 'A',
      file: null
    },
    form: {
      party: [],
      referencePeriod: {
          year : []
      }
    }
  });

  const updateConfig = async () => {
    var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/generate-config');
    if(response.config) {  
      config.value = response.config;
    }
  }

  updateConfig();

  return {
    config,
    updateConfig,
  }
  
}
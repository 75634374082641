<template>
  <div class="ibox generate-success">
    <div class="ibox-content p-md">

      <div v-if="showSuccessDeclaration">
        <div class="alert alert-success">
          <h4>{{ $t('intrastats.generate.success.title') }}</h4>
        </div>

        <div class="row">
            <div class="col-lg-12 p-xs">
              {{ $t('intrastats.generate.success.message') }}
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 p-xs">
              {{ $t('intrastats.generate.success.psi-id') }}
            </div>
            <div class="col-lg-9 p-xs">
              {{ generatedDeclaration.psiId }}
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 p-xs">
              {{ $t('intrastats.generate.success.reference-period') }}
            </div>
            <div class="col-lg-9 p-xs">
              {{ generatedDeclaration.formattedReferencePeriod }}
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 p-xs">
              {{ $t('intrastats.generate.success.nb-lines') }}
            </div>
            <div class="col-lg-9 p-xs">
              {{ generatedDeclaration.nbLines }}
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 p-xs">
              {{ $t('intrastats.generate.success.download-xsl') }}
            </div>
            <div class="col-lg-9 p-xs">
              <a :href="getFileURL(generatedDeclaration.XLSFile._id)" target="_blank"><i class="fa fa-file-excel-o"></i></a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 p-xs">
              {{ $t('intrastats.generate.success.download-xml') }}
            </div>
            <div class="col-lg-9 p-xs">
              <a :href="getFileURL(generatedDeclaration.XMLFile._id)" target="_blank"><i class="fa fa-file-code-o"></i></a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 p-xs">
              <a class="btn btn-primary" href="javascript:void(0)" @click="restartGenerate()">{{ $t('intrastats.generate.success.new-form') }}</a>
            </div>
        </div>
      
      </div>
      <div v-else>

        <Messages :messages="errors" type="error"></Messages>
        <Messages :messages="successes" type="success"></Messages>

        <form role="form" @submit="onFormSubmit" name="generateForm">
          <div class="form-group row">
              <label for="generateXMLEnvelopeId" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.envelope-id') }}</label>
              <div class="col-lg-9">
                <input id="generateXMLEnvelopeId" type="text" :placeholder="$t('intrastats.generate.form.envelope-id_placeholder')" class="form-control" v-model="generateForm.envelopeId" required>
              </div>
          </div>
          <div class="form-group row">
              <label for="generateXMLPartyType" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.party-type') }}</label>
              <div class="col-lg-9">
                <select id="generateXMLPartyType" class="form-control" v-model="generateForm.party.type" required>
                  <option value="PSI">{{ $t('intrastats.generate.form.party-type-psi') }}</option>
                  <option value="TDP">{{ $t('intrastats.generate.form.party-type-tdp') }}</option>
                </select>
              </div>
          </div>
          <div class="form-group row">
              <label for="generateXMLPartyRole" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.party-role') }}</label>
              <div class="col-lg-9">
                <select id="generateXMLPartyRole" class="form-control" v-model="generateForm.party.role" required>
                  <option value="sender">{{ $t('intrastats.generate.form.party-role-sender') }}</option>
                  <option value="PSI">{{ $t('intrastats.generate.form.party-role-psi') }}</option>
                </select>
              </div>
          </div>
          <div class="form-group row">
              <label for="generateXMLPartyName" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.party-name') }}</label>
              <div class="col-lg-9">
                <input v-if="config.form.party.length == 1" id="generateXMLPartyName" type="text" :placeholder="$t('intrastats.generate.form.party-name_placeholder')" class="form-control-plaintext" v-model="generateForm.party.name" disabled>
                <select v-if="config.form.party.length > 1" id="generateXMLPartyName" class="form-control" v-model="generateForm.party.name" required>
                  <option v-for="(party, index) in config.form.party" :key="index" :value="party.name">{{party.name}}</option>
                </select>
              </div>
          </div>
          <div class="form-group row">
              <label for="generateXMLPartyId" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.party-id') }}</label>
              <div class="col-lg-9">
                <input id="generateXMLPartyId" type="text" :placeholder="$t('intrastats.generate.form.party-id_placeholder')" class="form-control-plaintext" v-model="generateForm.party.id" disabled>
              </div>
          </div>
          <div class="form-group row">
              <label for="generateXMLReferencePeriod" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.reference-period') }}</label>
              <div class="col-lg-5">
                <select id="generateXMLReferencePeriod" class="form-control" v-model="generateForm.referencePeriod.month" required>
                  <option value="01">{{ $t('months.january') }}</option>
                  <option value="02">{{ $t('months.february') }}</option>
                  <option value="03">{{ $t('months.march') }}</option>
                  <option value="04">{{ $t('months.april') }}</option>
                  <option value="05">{{ $t('months.may') }}</option>
                  <option value="06">{{ $t('months.june') }}</option>
                  <option value="07">{{ $t('months.july') }}</option>
                  <option value="08">{{ $t('months.august') }}</option>
                  <option value="09">{{ $t('months.september') }}</option>
                  <option value="10">{{ $t('months.october') }}</option>
                  <option value="11">{{ $t('months.november') }}</option>
                  <option value="12">{{ $t('months.december') }}</option>
                </select>
              </div>
              <div class="col-lg-4">
                <select class="form-control" v-model="generateForm.referencePeriod.year" required>
                  <option v-for="(year, index) in config.form.referencePeriod.year" :key="index" :value="year">{{year}}</option>
                </select>
              </div>
          </div>
          <div class="form-group row">
              <label for="generateXMLPsiId" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.psi-id') }}</label>
              <div class="col-lg-9">
                <input id="generateXMLPsiId" type="text" :placeholder="$t('intrastats.generate.form.psi-id_placeholder')" class="form-control" v-model="generateForm.psiId" required>
              </div>
          </div>
          <div class="form-group row">
              <label for="generateXMLDeclarationTypeCode" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.declaration-type-code') }}</label>
              <div class="col-lg-9">
                <select id="generateXMLDeclarationTypeCode" class="form-control" v-model="generateForm.declarationTypeCode" required>
                  <option value="1">{{ $t('intrastats.generate.form.declaration-type-code-one') }}</option>
                  <option value="2">{{ $t('intrastats.generate.form.declaration-type-code-two') }}</option>
                  <option value="3">{{ $t('intrastats.generate.form.declaration-type-code-three') }}</option>
                  <option value="4">{{ $t('intrastats.generate.form.declaration-type-code-four') }}</option>
                  <option value="5">{{ $t('intrastats.generate.form.declaration-type-code-five') }}</option>
                </select>
              </div>
          </div>
          <div class="form-group row">
              <label for="generateXMLFlowCode" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.flow-code') }}</label>
              <div class="col-lg-9">
                <select id="generateXMLFlowCode" class="form-control" v-model="generateForm.flowCode" required>
                  <option value="A">{{ $t('intrastats.generate.form.flow-code-A') }}</option>
                  <option value="D">{{ $t('intrastats.generate.form.flow-code-D') }}</option>
                </select>
              </div>
          </div>
          <div class="form-group row">
              <label for="generateXMLFile" class="col-lg-3 col-form-label">{{ $t('intrastats.generate.form.file') }}</label>
              <div class="col-lg-9">
                <b-form-file
                  id="generateXMLFile"
                  v-model="generateForm.file"
                  :state="Boolean(generateForm.file)"
                  :placeholder="$t('intrastats.generate.form.file_placeholder')"
                  :drop-placeholder="$t('intrastats.generate.form.file_drop-placeholder')"
                  required
                />
              </div>
          </div>
          <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('intrastats.generate.form.button') }}</button>
        </form>

      </div>
    </div>
  </div>
</template>

<style scoped>
  .generate-success .fa {
    font-size: 1.3em;
  }
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, ComputedRef} from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Messages from '@root/src/client/components/panels/Messages.vue';

import { Message } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

import { useGenerateAdmin } from '../../composables/useGenerateAdmin';
import { useReportAdmin } from '../../composables/useReportAdmin';


export default defineComponent({
  props: {
      
  },
  components: {
    Messages
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { getFileURL } = useReportAdmin(props, context);
    const { generateForm, generateDeclaration, config } = useGenerateAdmin(props, context);

    const generatedDeclaration:Ref<any> = ref(null);

    const errors:Ref<Message[]> = ref([]);
    const successes:Ref<Message[]> = ref([]);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=generateForm] button' );
      laddaSubmit = Ladda.create(button!);
    })

    const onApiErrors = (apiErrors:Message[]) => {
      errors.value = apiErrors;
    }

    const onApiSuccesses = (apiSuccesses:Message[]) => {
      successes.value = apiSuccesses
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();
      
      // We clear the current messages
      errors.value = [];
      successes.value = [];

      laddaSubmit!.start();

      generateDeclaration({
        errorsHandler: onApiErrors,
        successesHandler: onApiSuccesses
      }).then((response:any) => {
        
        laddaSubmit!.stop();
        
        if(response.generated) {
          generatedDeclaration.value = response.declaration;
        }
      });
    }

    const showSuccessDeclaration = computed(() => {
      return generatedDeclaration.value != null;
    })

    const restartGenerate = () => {
      generateForm.value = {...config.value.defaultValues};
      generatedDeclaration.value = null;
    }

    return {
      showSuccessDeclaration,
      generatedDeclaration,
      getFileURL,
      restartGenerate,
      onFormSubmit,
      errors,
      successes,
      generateForm,
      config
    }
  }
});
</script>
import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useModuleAdmin } from "./useModuleAdmin";
import { useConfigAdmin } from "./useConfigAdmin";

interface GenerateAdminInput {
}

export function useGenerateAdmin(props:GenerateAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedModule, callModuleAdmin } = useModuleAdmin(props, {emit})
  const { config } = useConfigAdmin(props, {emit});

  const generateForm:Ref<any> = ref({
    envelopeId: '',
    party: {
        type: '',
        role: '',
        id: '',
        name: ''
    },
    referencePeriod: {
        month: '',
        year: '',
    },
    psiId: '',
    declarationTypeCode: '1',
    flowCode: 'A',
    file: null
  });

  const generateDeclaration = async (options:{successesHandler:Function, errorsHandler:Function}) => {

    var result:any = {
      generated:false,
      declaration:null
    }

    var input = {
      
    }

    var formData = new FormData();
    formData.append("envelopeId", generateForm.value.envelopeId);
    formData.append("partyType", generateForm.value.party.type);
    formData.append("partyRole", generateForm.value.party.role);
    formData.append("partyName", generateForm.value.party.name);
    formData.append("referencePeriodMonth", generateForm.value.referencePeriod.month);
    formData.append("referencePeriodYear", generateForm.value.referencePeriod.year);
    formData.append("psiId", generateForm.value.psiId);
    formData.append("declarationTypeCode", generateForm.value.declarationTypeCode);
    formData.append("flowCode", generateForm.value.flowCode);
    if(generateForm.value.file != null) {
      formData.append("file", generateForm.value.file!, (generateForm.value.file! as File).name);  
    }

    try {
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/generate', input, formData, options);
      if(response.generated) {
        result.generated = true;
        generateForm.value = {...config.value.defaultValues};
        result.declaration = response.declaration;
      }
      return result;
    }
    catch(error:any) {
      console.log(error);
    }
    return result;

  }  

  watch(
    config,
    (val:any, oldVal:any) => {
      generateForm.value = {...val.defaultValues};
    },
    { deep: true }
  )
  
  watch(
    () => generateForm.value.party.name,
    (val:any, oldVal:any) => {
      let party = config.value.form.party.find((element:any) => element.name == val);
      if(party) {
        generateForm.value.party.id = party.id;
      }
    },
    { deep: false }
  )

  return {
    config,
    generateForm,
    generateDeclaration
  }
  
}